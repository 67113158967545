// import { LogLevel } from '@azure/msal-browser'
import scotConfig from '../config'

export const msalConfig = {
    auth: {
        clientId: scotConfig.REACT_APP_MSAL_CLIENT_ID as string,
        authority: scotConfig.REACT_APP_MSAL_TENANT_AUTHORITY_URI as string,
        redirectUri: scotConfig.REACT_APP_MSAL_LOGIN_REDIRECT_URI as string
    },
    cache: {
        cacheLocation: scotConfig.REACT_APP_MSAL_CACHE_LOCATION as string,
        storeAuthStateInCookie: true
    },
    system: {
        tokenRenewalOffsetSeconds: 300
        // loggerOptions: {
        //     loggerCallback: (level: any, message: any, containsPii: any) => {
        //         if (containsPii) {
        //             return
        //         }
        //         switch (level) {
        //             case LogLevel.Error:
        //                 console.error(message)
        //                 return
        //             case LogLevel.Info:
        //                 console.info(message)
        //                 return
        //             case LogLevel.Verbose:
        //                 console.debug(message)
        //                 return
        //             case LogLevel.Warning:
        //                 console.warn(message)
        //         }
        //     }
        // }
    }
}

export const storageRequest = {
    scopes: [scotConfig.REACT_APP_MSAL_CLIENT_SCOPE as string]
}

export const loginRequest = {
    scopes: [scotConfig.REACT_APP_MSAL_CLIENT_SCOPE as string],
    forceRefresh: false
}
