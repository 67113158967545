import React, { ReactElement, useEffect, useState } from 'react'
import Modal from '../../atoms/Modal'
import ChangeLog from 'components/templates/DefaultLayout/ChangeLog'
import { _getLatestVersion } from '../../../services/API/_changeLog'
import preval from 'preval.macro'
import { useMsal } from '@azure/msal-react'

interface IAboutProps {
    aboutPanelOpen: any
    setAboutPanelOpen: any
}
const AboutModal: React.FC<IAboutProps> = (props): ReactElement => {
    const { aboutPanelOpen, setAboutPanelOpen } = props
    const [modalState, setModalState] = useState(false)
    const [data, setData] = useState<any>()
    const [loader, setLoader] = useState(false)

    const { instance, accounts } = useMsal()
    const [hasToken, setHasToken] = useState(false)

    useEffect(() => {
        const checkForToken = async () => {
            if (accounts && accounts.length > 0) {
                try {
                    const silentRequest = {
                        account: accounts[0],
                        scopes: ['User.Read']
                    }

                    const response = await instance.acquireTokenSilent(silentRequest)

                    if (response && response.accessToken) {
                        setHasToken(true)
                    }
                } catch (error) {
                    setHasToken(false)
                }
            } else {
                setHasToken(false)
            }
        }

        checkForToken()
    }, [accounts, instance])

    const onDismiss = () => {
        setAboutPanelOpen(false)
    }

    useEffect(() => {
        hasToken && _getLatestVersion(setLoader, setData)
    }, [hasToken])

    return (
        <>
            <Modal
                isModalOpen={aboutPanelOpen}
                onDismiss={onDismiss}
                title='About'
                minWidth='40rem'
            >
                <div className='aboutContainer'>
                    <div>
                        <b>Last Updated:</b>{' '}
                        {preval`module.exports = new Date().toLocaleString('sv-SE');`}
                    </div>
                    <div>
                        <b>Environment:</b> {process.env?.REACT_APP_BUILD_ENV || 'development'}
                    </div>
                    <div>
                        <b>Version:</b> {loader ? '' : data && data?.version}
                    </div>
                    <div>
                        <b>Release:</b>{' '}
                        <a href='#' onClick={() => setModalState(true)}>
                            Changelog
                        </a>
                    </div>
                </div>
            </Modal>
            <Modal
                isModalOpen={modalState}
                onDismiss={() => setModalState(false)}
                title={'Release Changelog'}
            >
                <div className='modalContainerBigSize'>
                    <ChangeLog />
                </div>
            </Modal>
        </>
    )
}

export default AboutModal
