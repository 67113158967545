import React, { useEffect, useState } from 'react'
import { _getGridData } from 'services/API/_changeLog'
import LoadingComponent from 'components/atoms/LoadingComponent'
import { convertUTCToLocal } from 'utils/UTILS'
import './ChangeLog.scss'

const ChangeLog: React.FC<any> = () => {
    const [data, setData] = useState<any>()
    const [loader, setLoader] = useState(false)

    async function getGridData() {
        await _getGridData(setLoader, setData)
    }

    useEffect(() => {
        getGridData()
    }, [])

    return (
        <>
            {loader ? (
                <LoadingComponent />
            ) : (
                <table className='changeLogTable'>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length > 0 && (
                            <tr className='versionRow'>
                                <td colSpan={3}>{data[0].version}</td>
                            </tr>
                        )}
                        {data &&
                            data?.map((row: any, index: number, arr: any) => (
                                <>
                                    {index > 0 && row.version !== arr[index - 1].version && (
                                        <tr key={`separator${index}`} className='versionRow'>
                                            <td colSpan={3}>{row.version}</td>
                                        </tr>
                                    )}
                                    <tr key={`row${index}`}>
                                        <td>{convertUTCToLocal(row.createdDate, 'YYYY-MM-DD')}</td>
                                        <td>{row.title}</td>
                                        <td>{row.description}</td>
                                    </tr>
                                </>
                            ))}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default ChangeLog
