import * as React from 'react'
import { Stack } from '@fluentui/react/lib/Stack'
import {
    stackItemStyles,
    outerStackTokens,
    innerStackTokens,
    itemContainerPadding
} from './PageBannerStyles'
import { useEffect, useState } from 'react'
import { _getLatestVersion } from '../../../services/API/_changeLog'
import { useMsal } from '@azure/msal-react'

const backgroundColor = {
    background: '#141414',
    color: '#FFF'
}
const pageHeaderStyle: React.CSSProperties = {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'uppercase'
}
const pageVersionStyle: React.CSSProperties = {
    paddingTop: '10px',
    paddingRight: '20px',
    color: '#b1b1b1',
    fontWeight: 600
}

interface IPageBaneer {
    heading: string
    subHeading?: string
}
export const PageBannerTop: React.FunctionComponent<IPageBaneer> = ({ heading }: IPageBaneer) => {
    const [data, setData] = useState<any>()
    const [loader, setLoader] = useState(false)

    const { instance, accounts } = useMsal()
    const [hasToken, setHasToken] = useState(false)

    useEffect(() => {
        const checkForToken = async () => {
            if (accounts && accounts.length > 0) {
                try {
                    const silentRequest = {
                        account: accounts[0],
                        scopes: ['User.Read']
                    }

                    const response = await instance.acquireTokenSilent(silentRequest)

                    if (response && response.accessToken) {
                        setHasToken(true)
                    }
                } catch (error) {
                    setHasToken(false)
                }
            } else {
                setHasToken(false)
            }
        }

        checkForToken()
    }, [accounts, instance])

    useEffect(() => {
        hasToken && _getLatestVersion(setLoader, setData)
    }, [hasToken])

    return (
        <Stack
            enableScopedSelectors
            tokens={outerStackTokens}
            styles={{
                root: backgroundColor
            }}
            className='pageBannerTop'
        >
            <Stack
                enableScopedSelectors
                styles={stackItemStyles}
                tokens={innerStackTokens}
                horizontalAlign='space-between'
                horizontal
            >
                <Stack.Item style={itemContainerPadding}>
                    <div style={pageHeaderStyle}>{heading}</div>
                </Stack.Item>
                <Stack.Item>
                    <div style={pageVersionStyle}>{loader ? '' : data && data?.version}</div>
                </Stack.Item>
            </Stack>
        </Stack>
    )
}
