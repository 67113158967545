import moment from 'moment'
import { DATE_FORMAT, DATE_TIME_FORMAT } from './CONSTANTS'
import { Providers } from '@microsoft/mgt-react'

export function WordSizeToPx(wordSize: number) {
    return `${Math.round(wordSize * 1.33)}px`
}

export function formatCategoryList(param: any) {
    const categoryNames = param.map((item: any) => item.esowCategoryName).join(', ')
    return categoryNames
}
export function getSupplierName(item: any) {
    const supplierName = item.data.masterEsowId !== 0 ? item.data.supplierName : 'Master ESOW'
    return supplierName
}
export function setforMasterEsow(item: any) {
    const roundNumber = item.data.masterEsowId !== 0 ? item.data.RoundNumber : ''
    return roundNumber
}
export function convertToFormattedTime(param: any, timeFormat: string = DATE_TIME_FORMAT) {
    const getTimeStamp = new Date(param).getTime()

    if (getTimeStamp) {
        return moment(getTimeStamp).format(timeFormat)
    }
    return ''
}

export function convertToFormattedDate(param: any, timeFormat: string = DATE_FORMAT) {
    if (param) {
        const getTimeStamp = new Date(param).getTime()
        return moment(getTimeStamp).format(timeFormat)
    }
    return ''
}

export function convertToFormattedTimeFromUTC(param: any, timeFormat: string = DATE_TIME_FORMAT) {
    if (new Date(param).getTime()) {
        return moment(new Date(`${param}Z`).getTime()).format(timeFormat)
    }
    return ''
}

export function convertUTCToCET(param: any, dateStyle: any = 'short', timeStyle: any = 'short') {
    const m = new Date(`${param}Z`)
    if (m instanceof Date && !isNaN(m.valueOf())) {
        return timeStyle !== ''
            ? m.toLocaleString('sv-SE', {
                  timeZone: 'Europe/Stockholm',
                  dateStyle: dateStyle,
                  timeStyle: timeStyle
              })
            : m.toLocaleString('sv-SE', {
                  timeZone: 'Europe/Stockholm',
                  dateStyle: dateStyle
              })
    }
    return ''
}

export const convertUTCToLocal = (utcDateTime: any, format = 'YYYY-MM-DD'): string => {
    if (!utcDateTime.endsWith('Z')) {
        utcDateTime += 'Z'
    }
    const date = new Date(utcDateTime)

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const options: Intl.DateTimeFormatOptions = {
        hour12: false,
        timeZone: userTimezone
    }

    if (format.includes('YYYY')) options.year = 'numeric'
    if (format.includes('MM')) options.month = 'numeric'
    if (format.includes('DD')) options.day = 'numeric'
    if (format.includes('HH')) options.hour = 'numeric'
    if (format.includes('mm')) options.minute = 'numeric'
    if (format.includes('ss')) options.second = 'numeric'

    return new Intl.DateTimeFormat('sv-SE', options).format(date)
}

export const getInitials = function (name: string) {
    const names = name?.split(',').reverse()
    let initials = names[0].trim().substring(0, 1).toUpperCase()

    if (names.length > 1) {
        initials += names[names.length - 1].trim().substring(0, 1).toUpperCase()
    }
    return initials
}

export const DraftJsToolbarWithAllOptions = {
    options: ['inline', 'fontSize', 'list', 'colorPicker'],
    inline: { options: ['bold', 'italic', 'underline'] },
    link: { defaultTargetOption: '_blank' }
}

export const setMiddayDate = function (date: any) {
    if (date) {
        const middayDate = new Date(date)
        middayDate.setHours(12, 0, 0, 0)
        return middayDate
    } else {
        return null
    }
}

export function generateUniqueKey(prefix = 'key') {
    const timestamp = new Date().getTime()
    return `${prefix}_${timestamp}`
}

export const truncateText = (text: string, maxChars: number) => {
    if (!text) {
        return ''
    }

    if (text.length > maxChars) {
        return text.substring(0, maxChars) + '...'
    }

    return text
}

export const getPeopleDataFromAD = (defPeople: any, qType = 'cdsid') => {
    if (defPeople !== undefined && defPeople.length) {
        const graphClient = Providers.globalProvider.graph.client
        return new Promise((resolve, reject) => {
            const promisesArray = []
            for (let n = 0; n < defPeople.length; n = n + 20) {
                const payload = {
                    requests: defPeople.slice(n, n + 20).map((person: any, index: number) => {
                        const personData: any = {}
                        personData.url =
                            qType === 'cdsid'
                                ? "/users?$filter=startswith(userPrincipalName,'" +
                                  person +
                                  "@') and userType eq 'Member'&$select=id,userPrincipalName,mail,department,personImage,displayName,userType,onPremisesExtensionAttributes"
                                : '/users/' +
                                  person +
                                  '?$select=id,userPrincipalName,mail,department,personImage,displayName,userType,onPremisesExtensionAttributes'
                        personData.method = 'GET'
                        personData.id = n + index
                        return personData
                    })
                }

                promisesArray.push(
                    new Promise((resolve, reject) => {
                        graphClient
                            .api('/$batch')
                            .post(payload)
                            .then((res: any) => {
                                resolve(res)
                            })
                            .catch((err: any) => {
                                reject(err)
                            })
                    })
                )
            }

            Promise.all(promisesArray)
                .then((finalResult) => {
                    const finalResultSorted: any = []
                    const allPeople: any = []
                    finalResult.forEach((res: any) => {
                        finalResultSorted.push(...res.responses)
                    })
                    finalResultSorted.sort((a: any, b: any) => {
                        return parseInt(a.id, 10) - parseInt(b.id, 10)
                    })

                    finalResultSorted.forEach((resp: any) => {
                        if (qType === 'cdsid') {
                            if (resp.body.value.length) {
                                const user: any = resp.body.value[0]
                                user.cdsid = user.userPrincipalName
                                    .substring(0, user.userPrincipalName.indexOf('@'))
                                    .toUpperCase()
                                allPeople.push(user)
                            }
                        } else {
                            if (resp.status === 200) {
                                const user = resp.body
                                user.cdsid = user.userPrincipalName
                                    .substring(0, user.userPrincipalName.indexOf('@'))
                                    .toUpperCase()
                                allPeople.push(user)
                            }
                        }
                    })

                    resolve(allPeople)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    } else {
        return Promise.resolve([])
    }
}

export async function blobUrlToFile(blobUrl: any, fileName: string) {
    const response = await fetch(blobUrl)
    const blobData = await response.blob()

    const file = new File([blobData], fileName, { type: blobData.type })

    return file
}

export function getCurrentMilestoneValue(item: any) {
    const { zipCreated, pdfCreated, signingRequestSent, signed, completed } = item
    let milestoneValue = ''
    if (zipCreated && !pdfCreated) {
        milestoneValue = 'Zip Created'
    } else if (zipCreated && pdfCreated && !signingRequestSent) {
        milestoneValue = 'Pdf Generated'
    } else if (zipCreated && pdfCreated && signingRequestSent && !signed) {
        milestoneValue = 'Signing Request Sent'
    } else if (zipCreated && pdfCreated && signingRequestSent && signed && !completed) {
        milestoneValue = signed
    } else if (zipCreated && pdfCreated && signingRequestSent && signed && completed) {
        milestoneValue = 'Completed'
    }
    return milestoneValue
}

export function isSignedMilestone(item: any): boolean {
    if (item === 'Zip Created' || item === 'Pdf Generated' || item === '') {
        return true
    }
    return false
}

export const paragraphOutLine = { border: '1px solid #CCCCCC', borderRadius: 5 }

export function renderFileSize(sizeInBytes: number): string {
    if (sizeInBytes < 1024) {
        return `${sizeInBytes} Bytes`
    } else if (sizeInBytes < 1024 ** 2) {
        const sizeInKB = sizeInBytes / 1024
        return `${sizeInKB.toFixed(2)} KB`
    } else if (sizeInBytes < 1024 ** 3) {
        const sizeInMB = sizeInBytes / 1024 ** 2
        return `${sizeInMB.toFixed(2)} MB`
    } else {
        const sizeInGB = sizeInBytes / 1024 ** 3
        return `${sizeInGB.toFixed(2)} GB`
    }
}
export function escapeSpecialChars(str: string) {
    return str
        .replace(/\\/g, '\\\\')
        .replace(/"/g, '\\"')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/\t/g, '\\t')
}

export const createEsowHeaderObj = (esowHeader: any) => {
    const esowHeaderParam = {
        esowHeaderId: esowHeader?.esowHeaderId || 0,
        esowDocumentId: esowHeader?.esowDocumentId,
        supplier: esowHeader?.supplier || '',
        commodityName: esowHeader?.commodityName || '',
        issuer: esowHeader?.issuer || '',
        project: esowHeader?.project || '',
        date: esowHeader?.date || '',
        page: 'string',
        securityClass: esowHeader?.securityClass || '',
        platformProgram1: esowHeader?.platformProgram1 || null,
        platformProgram2: esowHeader?.platformProgram2 || [],
        solution: esowHeader?.solution || null,
        art: esowHeader?.art || null,
        productModules: esowHeader?.productModules || [],
        purchasingTeam: esowHeader?.purchasingTeam || null,
        purchasingId: esowHeader?.purchasingId || null,
        description: esowHeader?.description || '',
        functionGroup: esowHeader?.functionGroup || [],
        rfqDate: esowHeader?.rfqDate || null,
        agreementDate: esowHeader?.agreementDate || null,
        completionStatus: esowHeader?.completionStatus || 0
    }

    return esowHeaderParam
}
